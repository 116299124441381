import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import Header from '../Header';
import Loading from '../Loading';

const NoDipa = () => {
    const history = useHistory();
    const location = useLocation();
    const [_noDipa, setNoDipa] = useLocalStorage("noDipa", false);
    const [_stratus, setStratus] = useLocalStorage("stratus", false);

	useEffect(() => {
        setNoDipa(true);
        const params = new URLSearchParams(location.search);
        const keepLoggedIn = params.get("keepLoggedIn");
        const isStratusPresent = params.get("stratus");
        if (isStratusPresent) {
            setStratus(true);
        } else {
            // If both STC and Stratus are installed, this will help switch between both of them
            setStratus(false);
        }
        if (keepLoggedIn) {
            history.push('/');
        } else {
		  history.push('/logout');
        }
    // eslint-disable-next-line
	},[]);

    return (
        <>
            <Header />
            <Loading />
        </>
    )
}

export default NoDipa