import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import Auth from './components/Auth'
import Logout from './components/Logout'
import MFP from './components/MFP'
import Processing from './components/Processing'
import Redirect from './components/test/Redirect'
import FromDipa from './components/FromDipa'
import ToDipa from './components/ToDipa'
import NoDipa from './components/NoDipa'


function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Switch>
          <Route path="/auth">
            <Auth/>
          </Route>
          <Route path="/logout">
            <Logout/>
          </Route>
          <Route path="/mobile">
            MOBILE
          </Route>
          <Route path="/processing">
            <Processing/>
          </Route>
          <Route path="/redirect">
            <Redirect/>
          </Route>
          <Route path="/dipa-redirect">
            <FromDipa/>
          </Route>
          <Route path="/dipa">
            <ToDipa/>
          </Route>
          <Route path="/no-dipa">
            <NoDipa />
          </Route>
          <Route path="/">
            <MFP/>
          </Route>
        </Switch>
      </Router>
    </I18nextProvider>
  );
}

export default App;
