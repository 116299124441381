import { useEffect } from 'react';
import { useLocalStorage } from '../../services/hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';

import Header from '../Header';
import Loading from '../Loading';

const ToDipa = () => {
    const location = useLocation();
    const [sqta, _] = useLocalStorage('dipa-sqta', '');
    const [stratus] = useLocalStorage("stratus", false);

    useEffect(() => {
        let appId = '0A024AC9';
        if (stratus) {
            appId = '0A024ADM';
        }
        const params = new URLSearchParams({
        });
        const query = new URLSearchParams(location.search);
        if (query.get('firstLaunch') !== null) {
            params.append('sqta', '');
        } else if (sqta && sqta.length > 0) {
            params.append('sqta', sqta);
        } else {
            params.append('sqta', '');
        }
        let queryString = params.toString();
        queryString = '?' + queryString;
        window.location = `http://localhost:8090/${appId}/index.py${queryString}`;
    })

    return (
        <>
            <Header />
            <Loading />
        </>
    )
}

export default ToDipa;