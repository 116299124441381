import React, { useEffect } from 'react';

const UserLogin = (props) => {
    const tenant = props.tenant;

    useEffect(() => {
        if (window.km_cookie) {
            window.km_cookie.DeleteCookie('us.bizhub.singles', null);
        }
        const callback = `${window.location.protocol}//${window.location.host}/auth`;  // "https://mfp.stratus.lol/auth";
        const state = window.btoa(
            unescape(encodeURIComponent(`${tenant?.userPoolId}|${tenant?.userPool?.Domain}|${tenant?.clientId}|${callback}|${tenant.region}|${tenant.id}|${tenant.name}`))
        );
        const logoutUrl = `${window.location.protocol}//${window.location.host}/logout`;  // "https://mfp.stratus.lol/logout";
        const params = new URLSearchParams({
            "client_id": tenant.clientId,
            "response_type": "code",
            "redirect_uri": callback,
            "logout_uri": logoutUrl,
            "scope": "email openid profile",
            "state": state
        });
        const authURL = `${tenant.slug}.auth.${tenant.region}.amazoncognito.com`

        window.location.replace(
            `https://${authURL}/login?${params.toString()}`
        );
    }, [tenant]);

    return (
        <div>

        </div>
    )
}

export default UserLogin