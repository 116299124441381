import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/scantrip/en/translation.json';
import es from './locales/scantrip/es/translation.json';
import de from './locales/scantrip/de/translation.json';
import fr from './locales/scantrip/fr/translation.json';
import it from './locales/scantrip/it/translation.json';
import ja from './locales/scantrip/ja/translation.json';
import zh from './locales/scantrip/zh/translation.json';
import enS from './locales/stratus/en/translation.json';
import esS from './locales/stratus/es/translation.json';
import deS from './locales/stratus/de/translation.json';
import frS from './locales/stratus/fr/translation.json';
import itS from './locales/stratus/it/translation.json';
import jaS from './locales/stratus/ja/translation.json';
import zhS from './locales/stratus/zh/translation.json';
import detector from 'i18next-browser-languagedetector';

const options = {
	order: ['querystring', 'navigator', 'cookie', 'localStorage'],
	lookupQuerystring: 'lang',
	lookupCookie: 'i18next',
	lookupLocalStorage: 'i18nextLang',
	caches: ['cookie', 'localStorage'],
	cookieOptions: { path: '/', sameSite: 'strict' }
}

i18next
	.use(detector)
	.use(initReactI18next)
	.init({
		detection: options,
		debug: true,
		ns: ['scantrip, stratus'],
		defaultNS: 'scantrip',
		returnEmptyString: false,
		nsSeparator: '::',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		},
		nonExplicitSupportedLngs: true,
		resources: {
			en: {
				scantrip: en,
				stratus: enS
			},
			es: {
				scantrip: es,
				stratus: esS
			},
			de: {
				scantrip: de,
				stratus: deS
			},
			fr: {
				scantrip: fr,
				stratus: frS
			},
			it: {
				scantrip: it,
				stratus: itS
			},
			ja: {
				scantrip: ja,
				stratus: jaS
			},
			zh: {
				scantrip: zh,
				stratus: zhS
			}
		}
	})

export default i18next;